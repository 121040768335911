import React from "react";

import Link from "../Link";

import { Flex } from "../../style/Grid";
import { Image } from "../../style/Image";
import { Text } from "../../style/Text";

import { htmlDecode } from "../../utils";
import { IcoPlace, IcoPhone, IcoClock } from "../../assets/Svg";

const ContactBlockBranch = ({ mb, contact, coords }) => (
  <Flex flexDirection={"column"} mb={mb}>
    {contact?.name && (
      <Text color="headlines" fontSize={20} lineHeight={"23px"} mb={2}>
        {htmlDecode(contact.name)}
      </Text>
    )}
    {contact?.mail && (
      <Flex as="span" alignItems="top" mb={2}>
        <svg width={20} height={16} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: "5px", minWidth: "20px" }}>
          <path
            opacity="0.9"
            d="M17.3477 4.34766V2.5L10 7.09766L2.65234 2.5V4.34766L10 8.90234L17.3477 4.34766ZM17.3477 0.652344C17.8346 0.652344 18.25 0.838542 18.5938 1.21094C18.9661 1.58333 19.1523 2.01302 19.1523 2.5V13.5C19.1523 13.987 18.9661 14.4167 18.5938 14.7891C18.25 15.1615 17.8346 15.3477 17.3477 15.3477H2.65234C2.16536 15.3477 1.73568 15.1615 1.36328 14.7891C1.01953 14.4167 0.847656 13.987 0.847656 13.5V2.5C0.847656 2.01302 1.01953 1.58333 1.36328 1.21094C1.73568 0.838542 2.16536 0.652344 2.65234 0.652344H17.3477Z"
            fill={"#00B8A6"}
          />
        </svg>
        <Link
          style={{
            wordBreak: "break-word",
            hyphens: "auto",
          }}
          href={`mailto:${contact.mail}`}
          variant={"regular"}
          fontSize={[15, null, 20]}
          lineHeight={["20px", null, "23px"]}
          ml={10}
        >
          {contact.mail}
        </Link>
      </Flex>
    )}
    {contact?.phone && (
      <Flex as="span" alignItems="center" mb={2}>
        <Image height={20} width={22} mr={10} src={IcoPhone} />
        <Link href={`tel:${contact.phone}`} variant={"regular"} fontSize={[15, null, 20]} lineHeight={["20px", null, "23px"]}>
          {contact.phone}
        </Link>
        {contact?.phoneCallHours && (
          <Text as={"span"} ml={2} fontSize={[15, null, 20]} lineHeight={["20px", null, "23px"]}>
            ({contact.phoneCallHours})
          </Text>
        )}
      </Flex>
    )}
    {contact?.address && (
      <Flex as="span" alignItems="flex-start" mb={2}>
        <Image height={20} minWidth={22} mr={10} src={IcoPlace} />
        <Link
          href={`https://www.google.com/maps/dir/?api=1&destination=${coords?.latitude},${coords?.longitude}`}
          variant={"regular"}
          target="_blank"
          rel="noopener noreferrer"
          fontSize={[15, null, 20]}
          lineHeight={["20px", null, "23px"]}
          dangerouslySetInnerHTML={{ __html: contact.address }}
        />
      </Flex>
    )}
    {contact?.openingHours && (
      <Flex as="span" alignItems="center" mb={2}>
        <Image height={20} width={22} mr={10} src={IcoClock} />
        <Text variant={"regular"} fontSize={[15, null, 20]} lineHeight={["20px", null, "23px"]}>
          {contact.openingHours}
        </Text>
      </Flex>
    )}
  </Flex>
);

export default ContactBlockBranch;
