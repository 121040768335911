import React, { useRef } from "react";
import { graphql } from "gatsby";

import SEO from "../../components/seo";
import Layout from "../../components/Layout";
import Header from "../../components/Header";
import Breadcrumb from "../../components/Breadcrumb";
import ContactBlockBranch from "../../components/ContactBlock/ContactBlockBranch";
import CareerBlock from "../../components/CareerBlock";

import { Box, Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Image } from "../../style/Image";
import { Wysiwyg } from "../../style/Wysiwyg";
import { GeoButton } from "../../style/Map";

import { URLS } from "../../const/const";
import { Text } from "../../style/Text";
import Branches from "../../components/Branches";
import { StaticImage } from "gatsby-plugin-image";

const BranchDetailTemplate = ({
  data: {
    wp: {
      branch: {
        title,
        branchContent,
        branchTeam: { chiefAdviser },
        featuredImage,
      },
    },
  },
}) => {
  const breadcrumbs = [
    {
      label: "Kontakt",
      link: URLS.contact,
    },
    { label: title, link: "/kontakt/title" },
  ];

  const bannerCareer = {
    fieldGroupName: "banner",
    title: "MÁTE ZÁJEM S&nbsp;NÁMI SPOLUPRACOVAT?",
    cta: {
      target: "",
      title: "Kariéra",
      url: URLS.career,
    },
    content:
      "<p>Díky stabilitě zázemí, letitým zkušenostem, rozsáhlým investicím do&nbsp;modernizaci systémů a&nbsp;především díky půl milionu spokojených klientů máme co nabídnout začínajícím poradcům i&nbsp;zkušeným obchodníkům. Přesvědčte se sami.</p>",
  };

  const contactInfo = {
    mail: branchContent?.contactDetails?.mail,
    phone: branchContent?.contactDetails?.phone,
    openingHours: branchContent?.contactDetails?.openingHours,
    phoneCallHours: branchContent?.contactDetails?.phoneCallHours,
    address: branchContent?.branchAddress,
  };

  const mapRef = useRef(null);
  const scrollToRef = ref => window && window.scrollTo(0, ref.current.offsetTop);

  return (
    <Layout>
      <SEO title={title} />
      <Header variant={"light"} activeNav={URLS.contact} noNav noPhone />

      <Wrapper>
        <Container flexDirection="column">
          <Flex flexDirection="column" mb={[2, null, 26]}>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <Heading as={"h1"} variant={"h1"} maxWidth={["100%", null, "75%", null, "50%"]} uppercase>
              {title}
            </Heading>
            {chiefAdviser?.name && (
              <Text fontSize={[2, null, 3]} color={"bodyText"} mt={22}>
                {chiefAdviser?.name}
              </Text>
            )}
            <Text fontSize={[1, null, 2]} color={"headlinesSecondary"}>
              Vedoucí kanceláře
            </Text>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg="panelPrimary" mb={5} pt={4} pb={[0, null, 50]}>
        <Container>
          <Flex width={1} flexWrap={"wrap"}>
            <Flex
              flexGrow={1}
              flexShrink={0}
              flexBasis={["100%", null, null, "calc(50% - 10px)"]}
              mr={[0, null, null, "20px"]}
              flexDirection={"column"}
              mb={[4, null, null, 0]}
            >
              <ContactBlockBranch
                contact={contactInfo}
                coords={{
                  latitude: branchContent?.googleMap?.latitude,
                  longitude: branchContent?.googleMap?.longitude,
                }}
                mb={[24, null, 40]}
              />

              <Flex pb={48} as={"span"} alignItems={"center"} variant={branchContent?.motto ? "borderDividerBottom" : null}>
                <GeoButton
                  onClick={() => {
                    scrollToRef(mapRef);
                  }}
                  variant="secondary"
                  mb={[4, 0, null, null, null]}
                  color={"white.100"}
                >
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.9688 15.4959C7.2732 16.732 8.84441 17.3501 10.6824 17.3501C12.5204 17.3501 14.0768 16.732 15.3516 15.4959C16.656 14.2311 17.3082 12.7075 17.3082 10.9251C17.3082 9.14283 16.656 7.63361 15.3516 6.39748C14.0768 5.13261 12.5204 4.50018 10.6824 4.50018C8.84441 4.50018 7.2732 5.13261 5.9688 6.39748C4.69405 7.63361 4.05668 9.14283 4.05668 10.9251C4.05668 12.7075 4.69405 14.2311 5.9688 15.4959ZM19.1758 10.0196H21.1324V11.8307H19.1758C18.9683 13.7567 18.1531 15.4097 16.7301 16.7895C15.3071 18.1694 13.6025 18.9599 11.6163 19.1612V21.0585H9.74859V19.1612C7.76235 18.9599 6.05774 18.1694 4.63476 16.7895C3.21178 15.4097 2.39654 13.7567 2.18902 11.8307H0.232422V10.0196H2.18902C2.39654 8.09356 3.21178 6.4406 4.63476 5.06075C6.05774 3.68089 7.76235 2.89034 9.74859 2.68911V0.791809H11.6163V2.68911C13.6025 2.89034 15.3071 3.68089 16.7301 5.06075C18.1531 6.4406 18.9683 8.09356 19.1758 10.0196ZM8.01434 8.33791C8.75547 7.61923 9.64483 7.25989 10.6824 7.25989C11.72 7.25989 12.6094 7.61923 13.3505 8.33791C14.0916 9.05658 14.4622 9.919 14.4622 10.9251C14.4622 11.9313 14.0916 12.7937 13.3505 13.5124C12.6094 14.2311 11.72 14.5904 10.6824 14.5904C9.64483 14.5904 8.75547 14.2311 8.01434 13.5124C7.2732 12.7937 6.90263 11.9313 6.90263 10.9251C6.90263 9.919 7.2732 9.05658 8.01434 8.33791Z"
                      fill="#EAF4F5"
                    />
                  </svg>
                  Zobrazit na mapě
                </GeoButton>
              </Flex>

              <Wysiwyg lineHeight={1} dangerouslySetInnerHTML={{ __html: branchContent?.motto }} />
            </Flex>

            <Box flexGrow={1} flexShrink={0} flexBasis={["100%", null, null, "calc(50% - 10px)"]} mr={["-15px", null, null, 0]} ml={["-15px", null, null, 0]}>
              <Image
                mt={{
                  xl: chiefAdviser?.name && chiefAdviser?.position ? "-245px" : "-135px",
                }}
                boxShadow="one"
                width="100%"
                ml={"auto"}
                borderRadius={[null, null, 1]}
                display="block"
                src={branchContent?.profilephoto ?? require("../../assets/images/contact/oz_default.jpg").default}
              />
            </Box>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper id={"pobocky"} ref={mapRef} pt={4}>
        <Container>
          <Flex alignItems={"center"} flexDirection={"column"} width={1} mb={72}>
            <Heading as={"h2"} fontSize={5} lineHeight={"38px"} uppercase mb={20} textAlign={"center"}>
              Zastavte se u nás
            </Heading>
            <Text textAlign={"center"} fontSize={2} lineHeight={"20px"} maxWidth={["100%", "80%"]}>
              KAPITOL má obchodní zastoupení ve&nbsp;všech regionech České republiky.
              <br />
              Kromě zde uvedených obchodních zastoupení funguje v&nbsp;městech a&nbsp;obcích ČR dalších také mnoho kanceláří KAPITOLu.
            </Text>
          </Flex>
        </Container>
      </Wrapper>

      <Branches
        highlightedMarker={{
          lat: branchContent?.googleMap?.latitude,
          long: branchContent?.googleMap?.longitude,
        }}
      />

      {/* TODO:
        Zobrazovat na mape i ostatni pobocky?
        Nejak zvyraznit pobocku, na jejiz strance zrovna jsem.
        Co delat, kdyz se klikne na jinou pobocku v mapce?
      */}

      <CareerBlock
        background={"dark"}
        content={bannerCareer}
        image={<StaticImage style={{ borderRadius: "6px" }} src={"../../assets/images/kariera-blok.jpeg"} alt={"Kariéra"} />}
      />
    </Layout>
  );
};

export default BranchDetailTemplate;

export const pageQuery = graphql`
  query BRANCH_DETAIL($slug: ID!) {
    wp {
      branch(id: $slug, idType: URI) {
        id
        title
        databaseId
        slug
        uri
        featuredImage {
          node {
            sourceUrl(size: MEDIUM_LARGE)
          }
        }
        branchContent {
          profilephoto
          branchAddress
          branchCode
          contactDetails {
            mail
            phone
            openingHours
            phoneCallHours
          }
          motto
          googleMap {
            latitude
            longitude
          }
        }
        branchTeam {
          chiefAdviser {
            name
            position
          }
        }
      }
    }
  }
`;
